import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from '../services/msal';
import AppInsightsContextProvider from '../components/AppInsights/ContextProvider'
import '../styles/globals.css'
import '../public/fonts/hco_fonts/hco_fonts.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "react-popupbox/dist/react-popupbox.css"
import { PopupboxContainer } from 'react-popupbox';
import { GoogleAnalytics, usePagesViews } from 'nextjs-google-analytics';

function MyApp({ Component, pageProps }) {
  usePagesViews(process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID);

  return (
    <MsalProvider instance={msalInstance}>
      <AppInsightsContextProvider>
        <GoogleAnalytics gaMeasurementId={process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID} />
        <Component {...pageProps} />
        <PopupboxContainer />
      </AppInsightsContextProvider>
    </MsalProvider>
  )
}

export default MyApp
